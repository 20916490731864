<template>
  <div>
    <div class="pititle">
      <div>
        <publicAnnounce></publicAnnounce>
      </div>
    </div>
  </div>
</template>
<script>
import publicAnnounce from "./components/publicAnnounce"
export default {
  components: { publicAnnounce },
  data() {
    return {
    };
  },
  methods: {

  },
};
</script>
